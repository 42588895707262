<template>
  <div v-if="relaticsStatus === 'success'" class="d-flex">
    <template v-if="availableViews && availableViews.length > 0">
      <portal :order="0" to="ant-toolbar-left">
        <v-menu key="relatics.view" offset-y>
          <template #activator="{ on, attrs }">
            <v-btn class="pl-2 pr-1" depressed small v-on="on">
              {{ (selectedView && selectedView.name) || 'View' }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="selectedView" color="primary">
              <v-list-item
                v-for="view in availableViews"
                :key="view.id"
                :value="view"
              >
                <v-list-item-title>{{ view.name }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-divider id="test" class="mx-2" inset vertical />
      </portal>
      <portal :order="2" to="ant-toolbar-left">
        <v-tooltip key="relatics.requirements-table" bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              :color="requirementsToggle ? 'primary' : ''"
              :ripple="false"
              class="mx-2"
              icon
              small
              v-bind="attrs"
              @click="requirementsToggle = !requirementsToggle"
              v-on="on"
            >
              <v-icon> mdi-format-list-bulleted-square</v-icon>
            </v-btn>
          </template>
          <span>View Requirements table</span>
        </v-tooltip>
      </portal>
      <gis-viewer v-if="isGisSelected">
        <template #sidebar-top>
          <relatics-sidebar
            :requirement-columns="requirementColumns"
            :requirement-records="requirementRecords"
            :requirements-loading="requirementsLoading"
            :requirements-toggle="requirementsToggle"
          />
        </template>
      </gis-viewer>
      <forge-viewer
        v-if="isForgeSelected"
        ref="forge-viewer"
        :client="relaticsClient"
        :custom-extensions="['RfisExtension']"
        :extension-options="[]"
        :extensions="[
          'Autodesk.DocumentBrowser',
          'Autodesk.Geolocation',
          'Autodesk.VisualClusters',
        ]"
        :models="relaticsModels"
        @modelsRendered="activatePropertiesDisplay"
      >
        <template #sidebar-top>
          <relatics-sidebar
            :requirement-columns="requirementColumns"
            :requirement-records="requirementRecords"
            :requirements-loading="requirementsLoading"
            :requirements-toggle="requirementsToggle"
          />
        </template>
      </forge-viewer>
    </template>
    <template v-else>
      <div class="d-flex align-center justify-center flex">
        The Forge and Gis module are not enabled
      </div>
    </template>
  </div>
  <div v-else class="d-flex justify-center align-center fill-height">
    <ant-loading />
  </div>
</template>

<script>
import { FORGE, GISVIEWER, RELATICS } from '@/modules/modules';
import { mapGetters } from 'vuex';
import { Portal } from 'portal-vue';
import RelaticsSidebar from '@/components/Modules/Relatics/RelaticsSidebar';
import ForgeViewer from '@/components/Modules/Forge/ForgeViewer';
import GisViewer from '@/components/Modules/GisViewer/GisViewer';
import AntLoading from '@/components/AntLoading';
import { executeCustomModuleCall } from '@/services/api/module.api';

export default {
  name: 'Relatics',
  components: { Portal, RelaticsSidebar, AntLoading, ForgeViewer, GisViewer },
  data: () => {
    return {
      sidebarSize: 600,
      requirementRecords: [],
      requirementColumns: [],
      requirementsLoading: false,
      requirementsToggle: true,
      selectedView: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'relaticsStatus',
      'relaticsClient',
      'relaticsModels',
      'relaticsSettings',
      'selectedSbsObject',
      'relaticsSbsRequirements',
      'relaticsRequirements',
      'selectedSbsVersion',
    ]),
    availableViews() {
      return this.project.modules
        .filter((m) => [GISVIEWER, FORGE].indexOf(m.route) > -1)
        .map((m) => ({
          id: m.id,
          name: m.name,
          route: m.route,
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    isForgeSelected() {
      return this.selectedView?.route === FORGE;
    },
    isGisSelected() {
      return this.selectedView?.route === GISVIEWER;
    },
  },
  watch: {
    selectedSbsObject(value) {
      if (value) {
        this.fetchRequirementForSbsCode(value.code);

        if (this.isForgeSelected) {
          this.$refs['forge-viewer'].objectPropertiesTree = [];
          let selectedObject = [];
          this.$refs['forge-viewer'].modelObjects.forEach((item) => {
            let forgeModel = this.relaticsModels.find(
              (x) => x.urn === item.urn
            );
            selectedObject.push(
              item.properties.find((object) => {
                let sbs = this.fetchFromObject(
                  object.properties,
                  forgeModel.sbs_parameter
                );
                return sbs === value.code;
              })
            );
          });

          if (selectedObject.length === 1) {
            let object = selectedObject[0];

            Object.keys(object.properties).forEach((key) => {
              let treeItem = {
                name: key,
                children: [],
              };
              Object.keys(object.properties[key]).forEach((propKey) => {
                let childProp = {
                  name: propKey,
                  value: object.properties[key][propKey],
                };
                treeItem.children.push(childProp);
              });
              this.$refs['forge-viewer'].objectPropertiesTree.push(treeItem);
            });
          } else {
            if (selectedObject.length > 1) {
              this.$store.commit('showNotification', {
                content:
                  'multiple objects found with same SBS code. This is not possible',
                color: 'error',
              });
            } else if (selectedObject.length === 0) {
              this.$store.commit('showNotification', {
                content: '0 objects found with selected SBS code',
                color: 'error',
              });
            }
          }
        }
      } else {
        this.requirementRecords = [];
        this.objectPropertiesTree = [];
      }
    },
    availableViews: {
      handler(val) {
        if (val?.length > 0) {
          this.selectedView = val[0];
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchModuleData();
    this.$store.commit('open_sbs_sidebar');
  },
  beforeDestroy() {
    this.$store.commit('close_sbs_sidebar');
  },
  methods: {
    async fetchRequirementForSbsCode(sbscode) {
      this.requirementRecords = [];
      this.requirementColumns = [];
      this.requirementsLoading = true;

      let response = await executeCustomModuleCall(
        this.project.id,
        this.project.modules.find((module) => module.route === RELATICS).id,
        'getRequirementsBySbsObject',
        {
          sbscode: sbscode,
          version_timestamp: this.selectedSbsVersion?.created_at ?? null,
        }
      );

      response.columns.forEach((column) => {
        this.requirementColumns.push({
          text: column.ant_column,
          value: column.ant_column,
        });
      });

      this.requirementRecords = response.records;
      this.requirementsLoading = false;
    },
    fetchModuleData() {
      this.$store.dispatch('fetchRelaticsModuleData', {
        projectId: this.project.id,
        moduleId: this.project.modules.find(
          (module) => module.route === RELATICS
        ).id,
        sessionId: this.$route.params.sessionId ?? null,
      });
    },
    fetchFromObject(obj, prop) {
      if (typeof obj === 'undefined') {
        return false;
      }

      const _index = prop.indexOf('.');
      if (_index > -1) {
        return this.fetchFromObject(
          obj[prop.substring(0, _index)],
          prop.substr(_index + 1)
        );
      }

      return obj[prop];
    },
    activatePropertiesDisplay() {
      setTimeout(() => {
        this.$refs['forge-viewer'].toggleObjectProperties();
      }, 500);
    },
  },
};
</script>

<style></style>
